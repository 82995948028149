<template>
  <svg
    class="v-svg-arrow-next"
    fill="none"
    height="14"
    viewBox="0 0 6 14"
    width="6"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L5 7L1 13"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<style lang="scss">
.v-svg-arrow-next {
  fill: none;
}
</style>
